/* eslint-disable */
import { GoogleLogin } from "react-google-login";

interface SignIntoGMBProps {
  setGMBAccessToken: any;
}

const SignIntoGMB: React.FC<SignIntoGMBProps> = ({ setGMBAccessToken }) => {
  function responseGoogle(response) {
    console.log(response, "response");
    if (response.tokenObj.access_token) {
      localStorage.setItem("gmb_access_token", response.tokenObj.access_token);
      setGMBAccessToken(response.tokenObj.access_token);
    } else {
      alert(
        "Please sign into ProPhone Support gmail using the button at the bottom of the screen."
      );
    }
  }

  return (
    <>
      <div className={`bg-white m-12 rounded-lg p-6`}>
        <h2 className="pb-8">Google My Business</h2>
        <GoogleLogin
          clientId="555479901310-7nf23if6or5cter47gt3ifcg4uhgoc69.apps.googleusercontent.com"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
          children={<button className={`max-h-16`}>Sign In</button>}
          className="border-none shadow-none bg-red-500"
        />
      </div>
    </>
  );
};

export default SignIntoGMB;
