/* eslint-disable */
import { useAtom } from "jotai";
import React, { useState } from "react";
import globalState from "../../../store";

const ServiceBellToggle = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [isChecked, setIsChecked] = useState(
    globalState?.service_bell_enabled || false
  );
  function handleCheckboxChange(event) {
    setIsChecked(event.target.checked);
    setGlobalState({
      ...globalStateAtom,
      service_bell_enabled: event.target.checked,
    });
  }
  return (
    <div className="flex flex-col justify-start items-start bg-white p-6 rounded-lg mt-8">
      <h2 className="text-h1 font-bold">Enable Service Bell</h2>
      <label className="flex ml-2">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <p className="ml-2">Enable Service Bell</p>
      </label>
    </div>
  );
};

export default ServiceBellToggle;
